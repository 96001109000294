import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/SEO"

const VenueInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "lakeBG.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      leftContainer: file(relativePath: { eq: "leftContainer.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rightContainer: file(relativePath: { eq: "rightContainer.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Venue" />
      <section className="venue__container">
        <BackgroundImage
          Tag="article"
          className="venue__header-image"
          fluid={data.header.childImageSharp.fluid}
        >
          <article className="venue__header-content-container">
            <h1 className="venue__header-content-title">About The Venue</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. minim veniam, quis nostrud exerci tation ullamcorper
              suscipit lobortis.
            </p>
          </article>
        </BackgroundImage>
        <article className="venue__features-container">
          {/* Image courtesy of Dan Gold on Unsplash https://unsplash.com/@danielcgold */}
          <BackgroundImage
            Tag="div"
            className="venue__features-left-img"
            fluid={data.leftContainer.childImageSharp.fluid}
          >
            <div className="venue__features-container-left-bgblend">
              <ul className="venue__features-container-left-list">
                <li className="venue__features-left-list-item">
                  &#10148; Feature One
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Two
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Three
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Four
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Five
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Six
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Seven
                </li>
                <li className="venue__features-left-list-item">
                  &#10148; Feature Eight
                </li>
              </ul>
            </div>
          </BackgroundImage>{" "}
          <span className="venue__features-img-divider"></span>
          {/* Image courtesy of Tomas Yates on Unsplash https://unsplash.com/@tomas_yates*/}
          <BackgroundImage
            Tag="div"
            className="venue__features-right-img"
            fluid={data.rightContainer.childImageSharp.fluid}
          ></BackgroundImage>{" "}
        </article>
        <article className="venue__center-content-container">
          <div className="venue__center-content-p">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim
          </div>
          <div className="venue__center-content-p">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim
          </div>
          <div className="venue__center-content-p">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default VenueInfo
